import styled from "styled-components";

export const FooterContainer = styled.div`
background: #f7f7f7;
  > .wrapper {
    max-width: 1033px;
    margin: 0 auto;
    padding: 54px 20px 0;
    .heading {
      text-align: center;
      h4 {
        font-size: 26.9px;
        font-weight: 700;
        line-height: 32.55px;
        margin-bottom: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin-right: 5px;
          width: 28px;
        }
        span {
          color: #8b2cf5;
        }
      }
      h3 {
        font-size: 30px;
        font-weight: 700;
        line-height: 36.31px;
      }
    }
  }
  .box-wrapper {
    display: flex;
    justify-content: center;
    gap: 47px;
    text-align: center;
    margin-bottom: 88px;
    margin-top: 50px;
    flex-wrap: wrap;
    > div {
      border: 2px solid #e8e8e8;
      box-shadow: 0px 0px 8px 0px #00000026;
      border-radius: 30px;
      padding: 51px 15px 27px;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 8px;
      color: #000;
      flex: 1;
      max-width: 230px;
      min-width: 230px;
      .icon-wrapper {
        width: 65px;
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #e8e8e8;
        border-radius: 10px;
        margin-bottom: 10px;
      }
      h4 {
        font-size: 18px;
        font-weight: 700;
        line-height: 21.78px;
        text-align: left;
      }
      p {
        font-size: 12.68px;
        font-weight: 400;
        line-height: 15.35px;
        text-align: center;
      }
      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 14.52px;
        margin: 0;
      }
    }
  }
  .end-footer {
    background: #f7f7f7;
    padding: 32px 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      font-size: 12.68px;
      font-weight: 400;
      line-height: 15.35px;
      a {
        color: #000;
      }
      span{
        padding-right: 9px;
       
      }
      .all-right{
        padding-left: 9px;
      }
    }
  }

  @media (max-width: 992px) {
    > .wrapper {
      padding: 36px 30px 57px;
    }
    .box-wrapper {
      /* display: grid;
      grid-template-columns: 1fr 1fr; */
      gap: 40px;
      margin-top: 51px;
      margin-bottom: 0;
    }
  }
`;
