import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../src/assets/css/style.css";
import Layout from "./components/Layout";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Pricing from "./components/Pricing";
import HomeV2 from "./components/HomeV2";
import Privacy from "./components/Privacy";
// import Privacy from "./components/Privacy";
function App() {
  return (
    // <Routes>
    //   <Route path="/" element={<Layout />}>
    //     <Route index element={<HomeV2 />} />
    //     <Route path="/privacy-policy" element={< Privacy/>} />
    //     <Route path="/v2" element={<HomeV2 />} />
    //     {/* <Route path="/pricing" element={<Pricings />} /> */}
    //   </Route>
    // </Routes>

    <Routes>
      <Route path="/"
        element={<Layout />} >
        <Route path="/"
          element={<HomeV2 />} />
      </Route>
      <Route
        path="/privacy-policy"
        element={
          <Privacy />
        }
      />
    </Routes>

  );
}

export default App;
