import Slider from "react-slick";

import { PopupButton } from "react-calendly";
import { Outlet, Link } from "react-router-dom";
// import HomeBannerImage1 from "../assets/images/home-banner.png";
import HomeBannerImage2 from "../assets/images/AI-graphic.png";
import SideLine from "../assets/images/side-line.png";
import SideLineMobile from "../assets/images/side-line-mobile.png";
// import InquiryIcon from "../assets/icon/Inquiry.svg";
// import VoiceIcon from "../assets/icon/Voice.svg";
// import AuditIcon from "../assets/icon/Audit.svg";
// import AiBanner from "../assets/images/why-use-AI.png";
// import AiBannerMobile from "../assets/images/why-use-AI-mobile.png";
// import BottomIcon from "../assets/icon/bottom-icon.svg";
// import PlayIcon from "../assets/icon/circled-play.svg";
import StarIcon from "../assets/icon/star-icon.svg";
import InterviewBanner from "../assets/images/interview-banner.png";
import PlayBlueIcon from "../assets/icon/play-svgrepo.svg";
import PauseBlueIcon from "../assets/icon/pause-svgrepo-com1.svg";

// import intro from "/videos/intro.mp4"
// import ReportImg from "../assets/images/mock-report-graphic.png";
// import CvIcon from "../assets/icon/cv-icon.svg";
import AssessmentOne from "../assets/images/box-1.png";

import AssessmentTwo from "../assets/images/box-2.png";
import AssessmentThree from "../assets/images/box-3.png";
import CV from "../assets/images/CV.png";
import BulletList from "../assets/images/bullet-list.png";
import Tasks from "../assets/images/Tasks.png";
import Voice from "../assets/images/Voice.png";
import Scales from "../assets/images/Scales.png";
import AlignLeft from "../assets/images/Align-Left.png";
import Audit from "../assets/images/Audit.png";
import Rating from "../assets/images/Rating.png";
import RightImage from "../assets/images/right-icon.png";
import LeftImage from "../assets/images/left-icon.png";
import StarWhiteIcon from "../assets/icon/star-icon-white.svg";
import TopBanner from "../assets/images/handshake-banner.png";
import ArrowBlueIcon from "../assets/icon/more-icon.svg";
import CopilotsBoxIcon1 from "../assets/icon/CopilotsBoxIcon1.svg";
import CopilotsBoxIcon2 from "../assets/icon/CopilotsBoxIcon2.svg";
import CopilotsBoxIcon3 from "../assets/icon/CopilotsBoxIcon3.svg";
import SliderMainImg1 from "../assets/images/SliderMainImg1.png";
import SliderMainImg2 from "../assets/images/SliderMainImg2.png";
import SliderMainImg3 from "../assets/images/SliderMainImg3.png";
import SliderMainImg4 from "../assets/images/SliderMainImg4.png";
import SliderMainImg5 from "../assets/images/SliderMainImg5.png";
import SliderMainImg6 from "../assets/images/SliderMainImg6.png";

import BottomSliderImg1 from "../assets/images/BottomSliderImg1.png";
import BottomSliderImg2 from "../assets/images/BottomSliderImg2.png";
import BottomSliderImg3 from "../assets/images/BottomSliderImg3.png";
import React, { useState, useRef, useEffect } from "react";

import SliderMobile1 from "../assets/images/slider-mobile1.png";
import SliderMobile2 from "../assets/images/slider-mobile2.png";
import SliderMobile3 from "../assets/images/slider-mobile3.png";
import SliderMobile4 from "../assets/images/slider-mobile4.png";
import SliderMobile5 from "../assets/images/slider-mobile5.png";
import SliderMobile6 from "../assets/images/slider-mobile6.png";
import {
  HomeContainer,
  HiringJustSection,
  WhyUseAi,
  SetupAssistant,
  LiveInterview,
  Assessment,
  AiPowered,
  Testimonials,
  TabContainer,
  TabHeader,
  Tab,
  TabContent,
  ProgressContainer,
  ProgressBar,
  ProgressLabel,
  HowItWork,
  AboutUs,
} from "../assets/css/Styled/HomeStyled";
import Footer from "./footer";
const HomeV2 = () => {
  const videoRef = useRef();
  const [playStatus, setPlayStatus] = useState(false);
  const sliderRef = useRef(null)

  const [activeSlide, setActiveSlide] = useState(0); // Initialize with the default slide index


  var settings1 = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    waitForAnimate: false,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  const goToSlides = (index) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
      setActiveSlide(index); // Update active slide index
    }
  };

  useEffect(() => {
    var videoPlayer = document.getElementById("video");
    setPlayStatus(videoPlayer.paused);
    videoPlayer.addEventListener('ended', function (e) {
      videoPlayer.currentTime = 0;
      videoPlayer.poster = 'videos/poster.png';
      setPlayStatus(true);
    });
  })

  function playOrPaused() {
    var videoPlayer = document.getElementById("video");
    if (playStatus) {
      videoPlayer.play();
      setPlayStatus(false)
    } else {
      videoPlayer.pause();
      setPlayStatus(true)
    }
  }


  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    afterChange: (current) => setActiveSlide(current),
  };
  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="46"
          height="16"
          viewBox="0 0 46 16"
          fill="none"
        >
          <path
            d="M45.7071 8.70711C46.0976 8.31658 46.0976 7.68342 45.7071 7.29289L39.3431 0.928932C38.9526 0.538408 38.3195 0.538408 37.9289 0.928932C37.5384 1.31946 37.5384 1.95262 37.9289 2.34315L43.5858 8L37.9289 13.6569C37.5384 14.0474 37.5384 14.6805 37.9289 15.0711C38.3195 15.4616 38.9526 15.4616 39.3431 15.0711L45.7071 8.70711ZM0 9H45V7H0V9Z"
            fill="white"
          />
        </svg>
      </div>
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="46"
          height="16"
          viewBox="0 0 46 16"
          fill="none"
        >
          <path
            d="M0.292892 7.2929C-0.0976296 7.68342 -0.0976295 8.31659 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41422 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928936C7.68054 0.538411 7.04738 0.538411 6.65685 0.928936L0.292892 7.2929ZM46 7L1 7L1 9L46 9L46 7Z"
            fill="white"
          />
        </svg>
      </div>
    );
  }
  const [activeTab, setActiveTab] = useState(1);
  const totalTabs = 4;

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const calculateProgress = () => {
    return ((activeTab + 1) / totalTabs) * 100;
  };

  const tabNames = ["Beginner", "Intermediate", "Experienced", "Proficient"];
  const tabColor = ["#F91B01", "#E09E01", "#1DBA11", "#0146B5"];

  const getContentForTab = (index) => {
    switch (index) {
      case 0:
        return (

          <p>
            Proficient in basic app development and debugging. Needs improvement
            in advanced programming, cross-platform development, and security
            practices for career advancement.
          </p>
        );
      case 1:
        return (
          <>
            <p>
              Proficient in basic app development and debugging. Needs
              improvement in advanced programming, cross-platform development,
              and security practices for career advancement.
            </p>
            <p
              style={{
                color: "#8A8A8A",
                fontSize: "12px",
                marginBottom: "5px",
              }}
            >
              Attempted: 2 | Skipped: 3 | Total: 5{" "}
            </p>
            <p style={{ color: "#8A8A8A", fontSize: "12px" }}>
              Time Taken: 35 Min 28 Sec{" "}
            </p>
          </>
        );
      case 2:
        return <p>Content for Tab 3</p>;
      case 3:
        return <p>Content for Tab 4</p>;
      default:
        return null;
    }
  };

  return (
    <HomeContainer>
      <HiringJustSection>
        {/* <div className="row1">
          <div className="text">
            <h2>Hiring just became easier</h2>
            <p>
              With an <span>AI Co-Pilot</span> that can screen talent and
              deliver the best candidate for any role.
            </p>
          </div>
          <div className="home-banner-image1-wrapper">
            <img src={HomeBannerImage1} alt="banner1" />
          </div>
        </div> */}
        <div className="row1">
          <div className="row1-inner-wrapper">
            <div className="text">
              <h2>
                Hiring is difficult. <img src="/static/media/star-icon.eceacbc5416777b9085bb703b0f0660d.svg" width="27" className="mb-2" alt="icon" /> AI
                can help{" "}
              </h2>
              <p>
                meet your <span>AI Hiring Copilot</span>
              </p>
              <button className="web-only get-started-btn">
                <PopupButton className=""
                  url="https://calendly.com/versityai/demo-recruitment-ai"
                  rootElement={document.getElementById("root")}
                  text="Request a Demo"
                />
                <span>
                  <img src={ArrowBlueIcon} alt="icon" />
                </span>
              </button>
            </div>

            <div className="image-wrapper">
              <img src={TopBanner} alt="banner" />


              <button className="mobile-only get-started-btn">
                <PopupButton className=""
                  url="https://calendly.com/versityai/demo-recruitment-ai"
                  rootElement={document.getElementById("root")}
                  text="Request a Demo"
                />
                <span>
                  <img src={ArrowBlueIcon} alt="icon" />
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="introducing-your-copilots">
          <h2 className="heading">
            Introducing your <span> Recruiting Copilots</span>
          </h2>
          <div className="copilots-box-wrapper">
            <div className="copilot-card">
              <img src={CopilotsBoxIcon1} alt="box-icon" />
              <h3>
                Screening <span>Copilot</span>
              </h3>
              <ul>
                <li>Turn job descriptions into perfect questions</li>
                <li>Analyze resume with just a click</li>
              </ul>
            </div>
            <div className="copilot-card">
              <img src={CopilotsBoxIcon2} alt="box-icon" />
              <h3>
                Interview <span>Copilot</span>
              </h3>
              <ul>
                <li>Runs an avatar based live interview</li>
                <li>Generates realtime feedback for each answer</li>
              </ul>
            </div>
            <div className="copilot-card">
              <img src={CopilotsBoxIcon3} alt="box-icon" />
              <h3>
                Analysis <span>Copilot</span>
              </h3>
              <ul>
                <li>Generates custom reports for each candidate</li>
                <li>Training and onboarding recommendations</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row2">
          <div className="home-banner-image2-wrapper">
            <img src={HomeBannerImage2} alt="banner2" />
            <img src={SideLine} className="side-line" alt="side-line" />
            <img
              src={SideLineMobile}
              className="side-line-mobile"
              alt="side-line"
            />
          </div>
          <div className="text">
            <h2>
              Imagine finding the perfect fit <b>Every Time.</b>
            </h2>
          </div>
        </div>
      </HiringJustSection>
      {/* <WhyUseAi>
        <div className="wrapper">
          <div className="ai-banner-wrapper">
            <img src={AiBanner} className="ai-banner" alt="ai-banner" />
            <img
              src={AiBannerMobile}
              alt="ai-banner"
              className="ai-banner-mobile"
            />
          </div>
          <div className="text">
            <div>
              <img src={InquiryIcon} alt="InquiryIcon" />
              <div>
                <h3>Hire for any role</h3>
                <p>with the AI Setup Assistant</p>
              </div>
            </div>
            <div>
              <img src={VoiceIcon} alt="InquiryIcon" />
              <div>
                <h3>Let AI run your first round of Interviews</h3>
                <p>with a live interview with each candidate</p>
              </div>
            </div>
            <div>
              <img src={AuditIcon} alt="InquiryIcon" />
              <div>
                <h3>Make more informed decisions</h3>
                <p>with a comprehensive report on each candidate</p>
              </div>
            </div>
          </div>
        </div>
      </WhyUseAi> */}
      {/* <SetupAssistant>
        <div className="inner-wrapper">
          <div className="left-section">
            <div className="setup">
              <h4>
                <img src={StarIcon} alt="star-icon" />
                AI
              </h4>
              <h5>Setup Assistant</h5>
            </div>
            <div className="text">
              <p>
                Our AI analyzes your job description and generates a
                <span>
                  dynamic set of questions tailored to the specific role.
                </span>
                This ensures you assess essential skills, problem-solving
                abilities, and even cultural fit. Focus on interviewing top
                candidates, not writing questions. Let AI streamline your hiring
                process.
              </p>
            </div>
          </div>
          <div className="right-section">
            <div className="center-section">
              <div className="round-section">
                <h4>Full Stack Engineer</h4>
                <span>Job Description</span>
              </div>
              <div className="text">
                <p>AI Generated Questions</p>
                <ol>
                  <li>
                    Explain the benefits and drawbacks of using a state
                    management library like Redux in a complex single-page
                    application
                  </li>
                  <li>
                    How would you secure a user login API endpoint against
                    common attacks like SQL injection?
                  </li>
                  <li>
                    Explain the role of a load balancer in a distributed system.
                  </li>
                </ol>
                <img className="bottom-icon" src={BottomIcon} alt="icon" />
              </div>
            </div>
          </div>
        </div>
      </SetupAssistant> */}
      <HowItWork>
        <div className="mobile-left-section">
          <h2>
            How <br></br> it works <img src={StarIcon} alt="icon" />
          </h2>
        </div>
        <div className="outer-wrapper">
          <div className="wrapper">
            <div className="left-section">
              <h2>
                How <br></br> it works
              </h2>
              <img src={StarIcon} alt="icon" />
            </div>
            <div className="slider-wrapper">
              <Slider ref={sliderRef} {...settings}>
                <div className="slider-inner-wrapper">
                  <div>
                    <div className="img-section">
                      <picture>
                        <source
                          media="(min-width:993px)"
                          srcset={SliderMainImg1}
                        />

                        <img src={SliderMobile1} alt="slider-banner" />
                      </picture>
                      <div className="btn-grp-copilot group-1">
                        <div class="btn-group mr-2 copilot-btn mt-5 text-center" role="group" aria-label="First group">
                          <button type="button" class="btn btn-secondary copilot-button first shadow" hr>Screening <span>Copilot</span></button>
                          <button onClick={() => goToSlides(2)} type="button" class="btn btn-secondary copilot-button second m-1">Interview <span>Copilot</span></button>
                          <button onClick={() => goToSlides(4)} type="button" class="btn btn-secondary copilot-button third ml-1">Analysis <span>Copilot</span></button>
                        </div>
                      </div>
                    </div>
                    <div className="text-section">
                      <h3>01</h3>
                      <h2>Resume Screening</h2>
                      <p>
                        Tired of screening through thousands of resumes? Let AI
                        find the right match in just a few clicks
                      </p>
                      <h5 className="mobile-view-tab">
                        Screening <span>Copilot</span>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="slider-inner-wrapper">
                  <div>
                    <div className="img-section">
                      <picture>
                        <source
                          media="(min-width:993px)"
                          srcset={SliderMainImg2}
                        />

                        <img src={SliderMobile2} alt="slider-banner" />
                      </picture>
                      <div className="btn-grp-copilot group-1">
                        <div class="btn-group mr-2 copilot-btn mt-5 text-center" role="group" aria-label="First group">
                          <button type="button" class="btn btn-secondary copilot-button first  shadow">Screening <span>Copilot</span></button>
                          <button onClick={() => goToSlides(2)} type="button" class="btn btn-secondary copilot-button second m-1 ">Interview <span>Copilot</span></button>
                          <button onClick={() => goToSlides(4)} type="button" class="btn btn-secondary copilot-button third m-1">Analysis <span>Copilot</span></button>
                        </div>
                      </div>
                    </div>
                    <div className="text-section">
                      <h3>02</h3>
                      <h2>Question Generation</h2>
                      <p>
                        AI generates questions for any position, saving you time
                        and research. No specialist knowledge required!
                      </p>
                      <h5 className="mobile-view-tab">
                        Screening <span>Copilot</span>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="slider-inner-wrapper">
                  <div>
                    <div className="img-section">
                      <picture>
                        <source
                          media="(min-width:993px)"
                          srcset={SliderMainImg3}
                        />

                        <img src={SliderMobile3} alt="slider-banner" />
                      </picture>
                      <div className="btn-grp-copilot group-2">
                        <div class="btn-group mr-2 copilot-btn mt-5 text-center" role="group" aria-label="First group">
                          <button onClick={() => goToSlides(0)} type="button" class="btn btn-secondary copilot-button first  m-1">Screening <span>Copilot</span></button>
                          <button type="button" class="btn btn-secondary copilot-button second shadow">Interview <span>Copilot</span></button>
                          <button onClick={() => goToSlides(4)} type="button" class="btn btn-secondary copilot-button third m-1">Analysis <span>Copilot</span></button>
                        </div>
                      </div>
                    </div>
                    <div className="text-section">
                      <h3>03</h3>
                      <h2>Live Interview</h2>
                      <p>
                        AI Avatar runs a live interview with the candidate,
                        speaking to them in natural language
                      </p>
                      <h5 className="mobile-view-tab">
                        Interview <span>Copilot</span>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="slider-inner-wrapper">
                  <div>
                    <div className="img-section">
                      <picture>
                        <source
                          media="(min-width:993px)"
                          srcset={SliderMainImg4}
                        />

                        <img src={SliderMobile4} alt="slider-banner" />
                      </picture>
                      <div className="btn-grp-copilot group-2">
                        <div class="btn-group mr-2 copilot-btn mt-5 text-center" role="group" aria-label="First group">
                          <button onClick={() => goToSlides(0)} type="button" class="btn btn-secondary copilot-button first  m-1">Screening <span>Copilot2</span></button>
                          <button type="button" class="btn btn-secondary copilot-button second shadow">Interview <span>Copilot</span></button>
                          <button onClick={() => goToSlides(4)} type="button" class="btn btn-secondary copilot-button third m-1">Analysis <span>Copilot</span></button>
                        </div>
                      </div>
                    </div>
                    <div className="text-section">
                      <h3>04</h3>
                      <h2>Candidates Response</h2>
                      <p>
                        Candidate answers by speaking to AI with the text
                        live transcribed during the interview
                      </p>
                      <h5 className="mobile-view-tab">
                        Interview <span>Copilot</span>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="slider-inner-wrapper">
                  <div>
                    <div className="img-section">
                      <picture>
                        <source
                          media="(min-width:993px)"
                          srcset={SliderMainImg5}
                        />

                        <img src={SliderMobile5} alt="slider-banner" />
                      </picture>
                      <div className="btn-grp-copilot group-3">
                        <div class="btn-group mr-2 copilot-btn mt-5 text-center" role="group" aria-label="First group">
                          <button onClick={() => goToSlides(0)} type="button" class="btn btn-secondary copilot-button first  m-1">Screening <span>Copilot</span></button>
                          <button onClick={() => goToSlides(3)} type="button" class="btn btn-secondary copilot-button second m-1">Interview <span>Copilot</span></button>
                          <button type="button" class="btn btn-secondary copilot-button third shadow">Analysis <span>Copilot</span></button>
                        </div>
                      </div>
                    </div>
                    <div className="text-section">
                      <h3>05</h3>
                      <h2>Smart Assessment</h2>
                      <p>
                        AI generates feedback in real time based on the
                        candidates answers
                      </p>
                      <h5 className="mobile-view-tab">
                        Interview <span>Copilot</span>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="slider-inner-wrapper">
                  <div>
                    <div className="img-section">
                      <picture>
                        <source
                          media="(min-width:993px)"
                          srcset={SliderMainImg6}
                        />

                        <img src={SliderMobile6} alt="slider-banner" />
                      </picture>{" "}
                      <div className="btn-grp-copilot group-3">
                        <div class="btn-group mr-2 copilot-btn mt-5 text-center" role="group" aria-label="First group">
                          <button onClick={() => goToSlides(0)} type="button" class="btn btn-secondary copilot-button first  m-1">Screening <span>Copilot</span></button>
                          <button onClick={() => goToSlides(2)} type="button" class="btn btn-secondary copilot-button second m-1">Interview <span>Copilot</span></button>
                          <button type="button" class="btn btn-secondary copilot-button third shadow">Analysis <span>Copilot</span></button>
                        </div>
                      </div>
                    </div>
                    <div className="text-section">
                      <h3>06</h3>
                      <h2>Smart Reports</h2>
                      <p>
                        AI generates a multi-page report about the candidate
                        including Pros & Cons and Onboarding Tips
                      </p>
                      <h5 className="mobile-view-tab">
                        Analysis <span>Copilot</span>
                      </h5>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </HowItWork>
      <LiveInterview>
        <div className="interview-wrapper">
          <div className="row-1 border padding-bottom-0">
            <div className="interview-banner">
              <div>
                <video id="video" controls={false} ref={videoRef} className="video-player" height="510" poster="videos/poster.png" >
                  <source autoplay={true} src='videos/intro.mp4' type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
          <div className="row-2">
            {
              playStatus ? <img onClick={playOrPaused} src={PlayBlueIcon} alt="icon" /> :
                <img onClick={playOrPaused} src={PauseBlueIcon} alt="Play or Pause icon" />
            }
            <div>
              <h2>Watch a live interview </h2>
              <p>Role: Marketing Manager </p>
            </div>
          </div>
        </div>
      </LiveInterview>
      {/* <Assessment>
        <div className="inner-wrapper">
          <div className="left-section">
            <div>
              <div className="setup">
                <h4>
                  <img alt="icon" src={StarIcon} />
                  AI <span>Candidate</span>
                </h4>
                <h5>Assessment</h5>
              </div>
              <img src={ReportImg} alt="img" />
            </div>
            <p className="text">
              Our AI generates in-depth smart reports for every candidate
              interview. These reports capture strengths, weaknesses, and
              detailed notes, providing a unbiased{" "}
              <span>data-driven analysis</span> to help you uncover hidden gems
              and make smarter hiring decisions.
            </p>
          </div>
          <div className="right-section">
            <TabContainer>
              <ProgressContainer>
                <ProgressBar progress={100} />
                <ProgressLabel progress={calculateProgress()}>
                  {Math.round(calculateProgress())}%
                </ProgressLabel>
              </ProgressContainer>
              <TabHeader>
                {[...Array(totalTabs)].map((_, index) => (
                  <Tab
                    key={index}
                    active={index === activeTab}
                    onClick={() => handleTabClick(index)}
                    style={{ color: tabColor[index] }}
                  >
                    {tabNames[index]}
                  </Tab>
                ))}
              </TabHeader>
              <TabContent>{getContentForTab(activeTab)}</TabContent>
            </TabContainer>
            <button>
              View Sample Report <img src={CvIcon} alt="icon" />
            </button>
          </div>
        </div>
      </Assessment> */}
      <AiPowered>
        <div className="wrapper">
          <img src={LeftImage} alt="icon" className="left-image" />
          <img src={RightImage} alt="icon" className="right-image" />
          <h4 onClick={() => { window.scroll(0, 0) }}>
            <img src="/static/media/star-icon.eceacbc5416777b9085bb703b0f0660d.svg" alt="" /> Recruitment<span>.AI</span>
          </h4>
          <h3>All of your Copilots packaged into one solution</h3>
          <div className="wrapper-box">
            <div className="box">
              <img src={AssessmentOne} alt="icon" className="box-image" />
              <h4>
                Screening <span>Copilot</span>
              </h4>
              <div className="media">
                <img
                  src={BulletList}
                  alt="media-img"
                  className="media__image"
                />
                <div className="media__content">
                  <p className="media__description">
                    Auto Generated interview questions based on the specific
                    field of the candidate
                  </p>
                </div>
              </div>
              <div className="media">
                <img src={Tasks} alt="media-img" className="media__image" />
                <div className="media__content">
                  <p className="media__description">
                    Questions can be tailored for varying levels of difficulty
                    and complexity
                  </p>
                </div>
              </div>
              <div className="media">
                <img src={CV} alt="media-img" className="media__image" />
                <div className="media__content">
                  <p className="media__description">
                    AI Resume Matching help identify the right candidate amongst
                    a sea of resumes
                  </p>
                </div>
              </div>
            </div>
            <div className="box">
              <img src={AssessmentTwo} alt="icon" className="box-image" />
              <h4>
                Interview <span>Copilot</span>
              </h4>
              <div className="media">
                <img src={Voice} alt="media-img" className="media__image" />
                <div className="media__content">
                  <p className="media__description">
                    Conducts the interview through natural spoken language with
                    an avatar{" "}
                  </p>
                </div>
              </div>
              <div className="media">
                <img src={Scales} alt="media-img" className="media__image" />
                <div className="media__content">
                  <p className="media__description">
                    Unbiased interview process ensuring fairness and consistency{" "}
                  </p>
                </div>
              </div>
              <div className="media">
                <img src={AlignLeft} alt="media-img" className="media__image" />
                <div className="media__content">
                  <p className="media__description">
                    Real time voice to text transcription based on the
                    candidates answers
                  </p>
                </div>
              </div>
            </div>
            <div className="box">
              <img src={AssessmentThree} alt="icon" className="box-image" />
              <h4>
                Analysis <span>Copilot</span>
              </h4>
              <div className="media">
                <img src={Audit} alt="media-img" className="media__image" />
                <div className="media__content">
                  <p className="media__description">
                    Comprehensive report generated for each candidate, including
                    Pros, Cons and Onboarding Practices
                  </p>
                </div>
              </div>
              <div className="media">
                <img src={Rating} alt="media-img" className="media__image" />
                <div className="media__content">
                  <p className="media__description">
                    Custom score for each candidate based on the level of
                    knowledge and accuracy of the answers
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AiPowered>




      <Testimonials>

        <div className="container newSlider">
          <div className="row text-center">
            <div className="col-lg-12">
              <div className="slider-container text-center">
                <Slider  {...settings1}>
                  <div>
                    <div class="card testinomials cards" >
                      <div class="card-body">
                        <h5>Anjali Thakur </h5>
                        <p className="profession"> Recruiter</p>
                        <p class="card-text"><i class="fa-solid fa-quote-left"></i>&nbsp; As an HR manager, I have to commend Recruitment.AI for its intuitive UI and seamless flow. Navigating through the platform is a breeze. <br /> One feature that stands out is the batch resume parsing capability. It's a game-changer for us, allowing us to upload and process multiple resumes simultaneously, saving us a significant amount of time and effort. &nbsp;<i class="fa-solid fa-quote-right"></i> </p>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="card  rounded testinomials" >
                      <div class="card-body">
                        <div className="auther">
                          <div className="mr-3">
                            <h5>Antra Sood </h5>
                            <p className="profession">Recruiter</p>
                          </div>
                        </div>
                        <p class="card-text"><i class="fa-solid fa-quote-left"></i>&nbsp; Recruitment.AI has completely transformed how we hire. The interface is intuitive, and the AI really understands our needs. <br /> Plus, the final reports are thorough and insightful. Highly recommend! &nbsp;<i class="fa-solid fa-quote-right"></i></p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="card testinomials" >
                      <div class="card-body">
                        <h5>Jyoti Kaur </h5>
                        <p className="profession">Recruiter</p>
                        <p class="card-text"><i class="fa-solid fa-quote-left"></i>&nbsp; Recruitment.AI has been a game-changer for our HR team! The time-saving features are incredible, and the live avatar adds a personal touch to interviews. <br /> Thanks to Recruitment.AI, we've hired some fantastic candidates. &nbsp;<i class="fa-solid fa-quote-right"></i></p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="card testinomials" >
                      <div class="card-body">
                        <h5>Muskan Parmar</h5>
                        <p className="profession">Recruiter</p>
                        <p class="card-text"><i class="fa-solid fa-quote-left"></i>&nbsp; The platform's question generation feature is fantastic – it's generating thoughtful and relevant questions for every job role I'm hiring for. <br /> And when it comes to evaluating candidates, the insights provided by Recruitment.AI are invaluable. Looking forward to exploring all the features further! &nbsp;<i class="fa-solid fa-quote-right"></i></p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="card testinomials" >
                      <div class="card-body">
                        <h5>Indrojeet Kanungoe</h5>
                        <p className="profession">Recruiter</p>
                        <p class="card-text"><i class="fa-solid fa-quote-left"></i>&nbsp; As a long-term client of Recruitment.AI, I can confidently say that this platform has been an integral part of our hiring success. Over the period, the question generation feature has consistently delivered tailored and insightful questions for a wide range of job roles. <br /> And when it comes to candidate evaluation, Recruitment.AI's analysis has provided us with invaluable insights, helping us make data-driven hiring decisions. &nbsp;<i class="fa-solid fa-quote-right"></i></p>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="card testinomials" >
                      <div class="card-body">
                        <h5>Ishrat Ali</h5>
                        <p className="profession">Recruiter</p>
                        <p class="card-text"><i class="fa-solid fa-quote-left"></i>&nbsp; Recruitment.AI is impressive overall, with its streamlined workflow and AI-driven features. The interactive avatar adds a unique touch to interviews, making the process engaging and insightful. &nbsp;<i class="fa-solid fa-quote-left"></i></p>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="wrapper">


          <div className="row-1 mt-5">
            <div className="setup">
              <h5>Testimonials</h5>

            </div>
            <div className="box">
              <h4>Candidate</h4>
              <p>
                I started working on a temporary position at <Link onClick={() => { window.scroll(0, 0) }} className="nav-link active" aria-current="page" to="/"><b>Recruitment.<span style={{ color: '#8b2cf5' }}>ai</span></b></Link> and
                then was able to go into the job of my dreams as an executive
                assistant hooray recruitment have always been on the end of the
                phone and end of an email responsive kind and thoughtful
              </p>
              <h5>Eric Smith</h5>
              <span>Technologent</span>
            </div>
          </div>
          <div className="box">
            <h4>Recruiter</h4>
            <p>

              We wholeheartedly recommend <Link onClick={() => { window.scroll(0, 0) }} className="nav-link active" aria-current="page" to="/"><b>Recruitment.<span style={{ color: '#8b2cf5' }}>ai</span></b></Link>  to any company looking
              for a recruitment partner that goes above and beyond. Their
              exceptional service, dedication to their values, and focus on
              people make them a standout choice in the recruitment industry.
            </p>
            <p>
              A huge thank you to the entire team for an outstanding recruitment
              experience. We look forward to working with you again in the
              future!
            </p>
            <h5>Eric Smith</h5>
            <span>Technologent</span>
          </div>
          <div className="box">
            <h4>Candidate</h4>
            <p>
              We wholeheartedly recommend <Link onClick={() => { window.scroll(0, 0) }} className="nav-link active" aria-current="page" to="/"><b>Recruitment.<span style={{ color: '#8b2cf5' }}>ai</span></b></Link>  to any company looking
              for a recruitment partner that goes above and beyond. Their
              exceptional service, dedication to their values, and focus on
              people make them a standout choice in the recruitment industry.
            </p>
            <p>
              A huge thank you to the entire team for an outstanding recruitment
              experience. We look forward to working with you again in the
              future!
            </p>
            <h5>Harry</h5>
            <span>Groupon</span>
          </div>
        </div> */}

        <div>


        </div>




      </Testimonials>
      <AboutUs >
        <div className="container about-Us">
          <div className="row ">
            
            <div className="col-md-4 col-sm-12 col-12 justify-content-center d-flex align-items-center">
             <h2 className="mb-5" style={{fontWeight:'bold'}}> <img src="/static/media/star-icon.eceacbc5416777b9085bb703b0f0660d.svg" width={'42'} alt="" />  <span className="mt-5">About Us</span></h2>
            </div>
            <div className="col-md-7 col-lg-7 col-sm-12 col-12 justify-text p-3">
              <p>Here at Recruitment.AI, we have a mission to address the challenges faced in the <br/> modern work world, specifically in recruiting and hiring. Drawing on their extensive experience and deep understanding of the industry, our founders have created a platform designed to transform the recruitment process, making it more efficient,  objective, and comprehensive.</p>
              <h6 className="mb-0" style={{fontWeight:'bold'}}>Join us as we revolutionize the future of recruitment.</h6>
              <img className="img-fluid mr-3 user-img" src="./users.png"  alt="" />
            </div>
          </div>
        </div>
      </AboutUs>
      <Footer />
    </HomeContainer>
  );
};

export default HomeV2;
