function Privacy() {
    document.title = 'Privacy & Policy'
  
      return (
          <>
            <div class="container-fluid ml-2 mb-2 mt-5">
          <div className="row">
            <div className="col-lg-12">
              <div className="privacy-heading w-100 text-center">
                <h3>Privacy Policy</h3>
              </div>
              <div className="term-description">
                <p>
                  Recruitment.AI Inc. is committed to protecting your privacy. You can visit most pages on our site without giving us any information about yourself. However, sometimes we do need information to provide services that you request and this privacy statement explains data collection and use in those situations. This privacy statement only applies to Recruitment.AI; it does not apply to other online or offline Recruitment.AI sites, products or services. Please read the complete Recruitment.AI privacy statement.
                </p>
                <p><strong>Collection of your Personal Information</strong></p>
                <p>
                  We will ask you when we need information that personally identifies you (personal information) or allows us to contact you. Generally, this information is requested when you are registering before entering a contest, ordering e-mail newsletters, joining a limited-access premium site, signing up for an event or training, or when purchasing and/or registering Recruitment.AI; Recruitment.AI products. Personal information collected by registering Recruitment.AI often is limited to e-mail address, language, country or location, but may include other information when needed to provide a service you requested
                </p>
                <p>
                  Otherwise you can browse registering Recruitment.AI without disclosing your identity. Our web servers whenever provide analytics and performance enhancement services also collects certain information about your computer hardware and software. This information may include: your IP address, browser type, operating system, domain name, access times and referring Website addresses. This information is used for the operation of the service, to maintain quality of the service and to provide general statistics regarding use of Recruitment.AI Inc.
                </p>
                <p>Recruitment.AI Inc. also collects information about which pages our customers visit within Recruitment.AI. This site visitation data is identified only by a unique ID number and it is never linked with personal information unless a user consents as described below.</p>
                <p>Use of your Personal Information</p>
                <ul>
                  <li>We use your personal information for the following purposes:</li>
                  <li>To ensure our site is relevant to your needs.</li>
                  <li>To deliver services, such as newsletters, events, training or software that you request or purchase.</li>
                  <li>To help us create and publish content most relevant to you.</li>
                  <li>To alert you to product upgrades, special offers, updated information and other new services from Recruitment.AI Inc., if you so request.</li>
                  <li>To allow you access to limited-entry areas of our site as appropriate.</li>
                </ul>
                <p>We will merge site-visitation data with anonymous demographic information for research purposes and we may use this information in aggregate to provide more relevant content. In some limited-entry sections of Recruitment.AI, with your approval, we will combine site-visitation data with your personal information in order to provide you with personalized content. If you decline permission, we will not provide you the personalized service and won't merge your personal information with site-visitation data.</p>
                <p>We occasionally hire other companies to provide limited services on our behalf, including packaging, mailing and delivering purchases, answering customer questions about products or services, sending postal mail and processing event registration. We will only provide those companies the information they need to deliver the service and they are prohibited from using that information for any other purpose.</p>
                <p>Recruitment.AI Inc. may disclose your personal information if required to do so by law or in the good-faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Recruitment.AI or the site; (b) protect and defend the rights or property of Recruitment.AI and its family of Web sites, or (c) act in urgent circumstances to protect the personal safety of Recruitment.AI employees, users of Recruitment.AI products or services, or members of the public.</p>
                <p>Your information may be stored and processed in the United States or any other country in which Recruitment.AI Inc. or its affiliates, subsidiaries or agents maintain facilities and by using this site, you consent to any such transfer of information outside of your country. Recruitment.AI abides by the safe harbour framework as set forth by the U.S. Department of Commerce regarding the collection, use and retention of data from the European Union.</p>
                <p><strong>Control of your Personal Information</strong></p>
                <p>When you register, or otherwise give us personal information, Recruitment.AI will not share that information with third parties without your permission, other than for the limited exceptions already listed. It will only be used for the purposes stated above.</p>
                <p>Recruitment.AI may send out periodic e-mails informing you of technical service or security issues related to a product or service you requested, or confirming you requested a product or service. You will not be able to choose to unsubscribe to these mailings, as they are considered an essential part of the service you have chosen, except for the users in EU member states and rights mentioned in foregoing recitals under head viz. GPDR</p>
                <p><strong>Security of your Personal Information</strong></p>
                <p>Recruitment.AI is committed to protecting the security of your personal information. We use a variety of security technologies and procedures to help protect your personal information from unauthorized access, use or disclosure.</p>
                <p><strong>Use of Cookies</strong></p>
                <p>When someone visits the site, a cookie is placed on the customer's machine (if the customer accepts cookies) or is read if the customer has visited the site previously. One use of cookies is to assist in the collection of the site visitation statistics described above.</p>
                <p>We also use cookies to collect information on which newsletter links are clicked by customers. This information is used to ensure we are sending information customers want to read. It is collected in aggregate form and never linked with your personal information.
                </p>
                <p>We make use of extensive technology to find out how many visitors clicked on key elements (such as links or graphics) on a Recruitment.AI Web page. We do not use this technology to access your personally identifiable information on Recruitment.AI; it is a tool we use to compile aggregated statistics about Recruitment.AI Web site usage. We may share aggregated site statistics with partner companies but do not allow other companies to place clear gifs on our sites.
                </p>
                <p>If you choose to not have your browser accept cookies from the Recruitment.AI Web site, you will be able to view the text on the screens, however you will not experience a personalized visit nor will you be able to subscribe to the service offerings on the site.</p>
                <p><strong>European Data Protection Rights - GDPR</strong></p>
                <p>Whenever your personal data is processed through our servers, it is subject to your consent and/or as vital for business operations including contractual and legal obligations, our security concerns and that of our clients.</p>
                <p>Recruitment.AI complies with applicable data protection laws in the European Economic Area, which if applicable includes then you have the following rights:</p>
                <ul>
                  <li> If the processing of personal data is based on your consent, you have a right to withdraw consent at any time for future processing;                   </li>
                  <li>Right to request from us, a “data controller” as defined in the law, access to and rectification of your personal data;</li>
                  <li>Right to request from us that your personal data are erased, subject to certain exceptions;</li>
                  <li>Right to object to the processing of your personal data; and</li>
                  <li>Right to lodge a complaint with a data protection authority.</li>
                </ul>
                <p>Where We Store and Process Personal Data</p>
                <p><strong>Our Retention of Personal Data</strong></p>
                <p>Recruitment.AI retains personal data for as long as necessary to provide the access to and use of the website, or for other essential purposes including but not limited to dispute resolution and contractual enforcements, complying with legal obligations. Actual retention periods can vary significantly as these retentions could vary for various data types and usage.</p>
                <p><strong>Enforcement of this Privacy Statement & Data Protection Officer (DPO)</strong></p>
                <p>If you have questions regarding this statement and have any privacy concerns or complaints, you could direct it to our Data Protection Office at [helpline email] by email or contact Recruitment.AI.</p>
                <p><strong>Changes to this Statement</strong></p>
                <p>Recruitment.AI will occasionally update this privacy statement. When we do, we will also revise the "last updated" date at the bottom of the privacy statement. For material changes to this statement, Recruitment.AI will notify you by placing prominent notice on the Web site.</p>
              </div>
            </div>
          </div>
        </div>
          </>
  
      )
  }
  export default Privacy