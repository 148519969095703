import React from "react";
import { Outlet, Link } from "react-router-dom";
import { PopupButton, useCalendlyEventListener } from "react-calendly";
import Logo from "../assets/images/Logo.png";
const Layout = () => {
  useCalendlyEventListener({
    onEventTypeViewed: () => { 
      
    },
    onEventScheduled: (e) => console.log(e.data.payload),
  });
  return (

    <>
      <nav className="navbar navbar-expand-lg custom-nav">
        <div className="container">
          <Link className="navbar-brand" to="/"><img  src={Logo} /></Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav ms-auto">
              {/* <Link className="nav-link active" aria-current="page" to="/">Home</Link> */}
              <Link className="nav-link text-center" to="https://employer.recruitment.ai/">Employer <br/> Login</Link> {/*For Production */}
              <Link className="nav-link text-center" to="https://candidate.recruitment.ai/">Candidate <br/> Login</Link> {/*For Production*/}
              {/* <Link className="nav-link text-center" to="https://devemployer.recruitment.ai/">Employer <br/> Login</Link> For Dev */}
              {/* <Link className="nav-link text-center" to="https://devcandidate.recruitment.ai/">Candidate <br/> Login</Link> For Dev */}
              {/* <Link className="nav-link" to="/privacy">Pricing</Link> */}
              {/* <Link className="nav-link" to="#"> Contact Us</Link> */}
              <PopupButton className="request-demo calendys"
                url="https://calendly.com/versityai/demo-recruitment-ai"
                rootElement={document.getElementById("root")}
                text="Request a Demo"
              />

            </div>

          </div>
        </div>
      </nav>
      <Outlet />
    </>
  )
}
export default Layout;