import StarIcon from "../assets/icon/star-icon.svg";
import EmailIcon from "../assets/icon/email.svg";
import Address from "../assets/icon/address.svg";
import CallIcon from "../assets/icon/call-phone.svg";
import PinIcon from "../assets/icon/pin.svg";
import DemoIcon from "../assets/icon/demo.svg";
import { Link } from "react-router-dom";
import { PopupButton } from "react-calendly";
import React, { useState } from 'react'
import { FooterContainer } from "../assets/css/Styled/FooterStyled";
const Footer = () => {
  const [date, setDate] = useState(new Date())

  function sendMail() {
    window.open('mailto:info@recruitment.ai')
  }
  return (
    <FooterContainer>
      <div className="wrapper">
        <div className="heading">
          <h3>Contact Us</h3>
        </div>
        <div className="box-wrapper">
          <div className="card-first">
            <div className="icon-wrapper">
              <img src={DemoIcon} alt="icon" />
            </div>
            <h4>
              <PopupButton className="request-demo"
                url="https://calendly.com/versityai/demo-recruitment-ai"
                rootElement={document.getElementById("root")}
                text="Request a Demo"
              />
            </h4>
          </div>
          <div className="card-second">
            <div className="icon-wrapper">
              <img src={Address} alt="icon" width={'40px'} height={'40px'} />
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-12 ">
                  <h6 className="mt-2" style={{ fontWeight: 'bold' }}> San Jose, CA</h6>
                  <p className="mb-2 ">929 Berryessa Rd, Suite</p>
                  <p className="mb-3 ">20 San Jose, CA 95133</p>
                  <p className="mb-2 ">Phone: +1(408) 618-6005</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card-third">
            <div className="icon-wrapper">
              <img src={EmailIcon} alt="icon" />
            </div>
            <a className="mail-to" href="mailto:info@recruitment.ai">
              <p><span>Reach out to us at</span><span style={{ fontWeight: 'bold' }}> info@recruitment.ai</span></p>
            </a>

          </div>
        </div>
      </div>
      <div className="end-footer">
        <p >
          <span> ©{date.getFullYear()} </span> <Link className="nav-link active" aria-current="page" to="/" onClick={() => { window.scroll(0, 0) }}> <img width="12" height="12" src="/static/media/star-icon.eceacbc5416777b9085bb703b0f0660d.svg" alt="" /> Recruitment.AI &nbsp;</Link> <span className="all-right"> All Rights Reserved</span>
        </p>
        <Link className="nav-link" to="/privacy-policy" onClick={() => { window.scroll(0, 0) }}>Privacy & Policy</Link>
      </div>
    </FooterContainer>
  );
};

export default Footer;
