import FullStackEngineerBG from "../../images/full-stack-engineerBG.png";
import HomeBannerBG from "../../images/home-banner-gradient-background.png";

import styled from "styled-components";

export const HomeContainer = styled.div`
  padding-top: 102px;
  font-family: "Inter", sans-serif;
`;

export const HiringJustSection = styled.div`
  /* .row1 {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    .text {
      max-width: 318px;
      color: #000000;
      h2 {
        font-size: 36.83px;
        font-weight: 400;
        line-height: 44.58px;
        margin-bottom: 15px;
      }
      p {
        font-size: 21.01px;
        font-weight: 400;
        line-height: 25.43px;
        text-align: left;
        color: #606060;
        margin: 0;
        span {
          color: #000;
          font-weight: 700;
        }
      }
    }
    .home-banner-image1-wrapper {
      max-width: 475px;
      width: 100%;
      flex: 1;
      img {
        width: 100%;
        max-width: 100%;
      }
    }
  } */
  .row1 {
    min-height: 639px;
    background-image: url(${HomeBannerBG});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    .row1-inner-wrapper {
      max-width: 991px;
      margin: 0 auto;
      width: 100%;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .get-started-btn {
        padding: 8px 15px 8px 30px;
        border-radius: 30px 0px 0px 0px;
        background: #ffffff;
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
        color: #000000;
        border-radius: 30px;
        margin-top: 57px;
        min-width: 203px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: none;
        button{
          background: none;
          border: 0;
          font-weight: bold;
        }
        span {
          width: 25px;
          height: 25px;
          padding: 5px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          box-shadow: 0px 0px 2.941176414489746px 0px #00000040;
        }
      }
      .mobile-only {
        display: none;
      }
      .image-wrapper {
        max-width: 451px;
        img {
          width: 100%;
        }
      }
      .text {
        color: #fff;
        max-width: 316px;
        margin-top: 46px;
        h2 {
          font-size: 36.83px;
          font-weight: 700;
          line-height: 44.58px;
          margin-bottom: 15px;
          position: relative;
          padding-bottom: 27px;
          &::after {
            content: "";
            height: 3px;
            width: 107px;
            background: #ffffff;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
        p {
          font-size: 21.01px;
          font-weight: 400;
          line-height: 25.43px;
          span {
            font-weight: 700;
          }
        }
      }
    }
  }
  .introducing-your-copilots {
    max-width: 1073px;
    margin: 0 auto;
    padding: 60px 30px;

    .heading {
      font-size: 29.42px;
      font-weight: 400;
      line-height: 35.6px;
      text-align: center;
      margin-bottom: 38px;
      span {
        font-weight: 700;
      }
    }
    .copilots-box-wrapper {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 34px;
    }
    .copilot-card {
      min-width: 305px;
      max-width: 305px;
      margin-top: 28px;
      display: flex;
      flex-direction: column;
      padding: 63px 40px 10px 24px;
      border-radius: 30px;
      position: relative;
      box-shadow: 0px 0px 10px 1px #00000040;
      > img {
        margin: 0 auto;
        width: 69px;
        height: 69px;
        position: absolute;
        top: -28px;
        right: 0;
        left: 0;
      }
      h3 {
        font-size: 18px;
        font-weight: 700;
        line-height: 21.78px;
        padding: 0 0 16px;
        color: #000;
        text-align: center;
        margin-bottom: 0;
        span {
          color: #8b2cf5;
        }
      }
      ul {
        li {
          font-size: 18.1px;
          font-weight: 400;
          line-height: 27px;
          text-align: left;
          color: #414141;
        }
      }
    }
  }
  .row2 {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    max-width: 991px;
    margin: 0 auto;
    padding: 85px 20px 125px;

    .text {
      max-width: 318px;
      color: #000000;
      margin-right: 107px;
      margin-bottom: 77px;
      h2 {
        font-size: 28px;
        font-weight: 400;
        line-height: 33.89px;
        text-align: right;
        max-width: 196px;
        margin: 0;
      }
    }
    .home-banner-image2-wrapper {
      max-width: 523px;
      width: 100%;
      flex: 1;
      position: relative;
      img {
        width: 100%;
        max-width: 100%;
      }
      .side-line {
        max-width: 332px;
        position: absolute;
        bottom: 92px;
        right: -302px;
      }
      .side-line-mobile {
        display: none;
      }
    }
  }
  @media (max-width: 992px) {
    .row1 {
      min-height: 624px;
      .row1-inner-wrapper {
        flex-direction: column;
        .web-only {
          display: none;
        }
        .mobile-only {
          display: flex;
        }
        .get-started-btn {
          margin: 35px 0 60px;
          margin-left: 60px;
        }
      }
    }

    .row2 {
      flex-direction: column;
      margin: 60px auto;
      max-width: 389px;
      padding: 0 47px;
      gap: 36px;
      .text {
        max-width: 100%;
        /* margin-top: 36px; */
        h2 {
          margin-top: 11px;
          max-width: 183px;
          font-family: Inter;
          font-size: 28px;
          font-weight: 400;
          line-height: 33.89px;
          text-align: right;
        }
      }
      .home-banner-image2-wrapper {
        .side-line {
          display: none;
        }
        .side-line-mobile {
          display: block;
          max-width: 95px;
          position: absolute;
          bottom: -139px;
          right: 8px;
        }
      }
    }
  }
  @media (max-width: 486px) {
    .row1 {
      .row1-inner-wrapper {
        .get-started-btn {
          margin-left: 0;
        }
      }
    }
  }
  @media (max-width: 370px) {
    .introducing-your-copilots .copilot-card {
      min-width: auto;
    }
  }
`;

export const WhyUseAi = styled.div`
  background: linear-gradient(105.17deg, #253776 17.24%, #131727 78.5%);
  .wrapper {
    max-width: 1072px;
    margin: 0 auto;
    padding: 132px 20px 77px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    .ai-banner-wrapper {
      max-width: 351px;
      width: 100%;
      flex: 1;
      .ai-banner {
        display: block;
      }
      .ai-banner-mobile {
        display: none;
      }
      img {
        width: 100%;
        max-width: 100%;
      }
    }
    .text {
      display: flex;
      flex-direction: column;
      gap: 73px;
      color: #fff;
      > div {
        display: flex;
        gap: 25px;
        h3 {
          font-size: 20.95px;
          font-weight: 700;
          line-height: 25.35px;
          margin: 0;
          margin-bottom: 3px;
        }
        p {
          font-family: Inter;
          font-size: 20.95px;
          font-weight: 400;
          line-height: 25.35px;
          text-align: left;
          margin: 0;
        }
      }
    }
  }
  @media (max-width: 992px) {
    .wrapper {
      padding: 189px 51px 82px;
      flex-direction: column;
      position: relative;
      margin-top: 253px;
      .ai-banner-wrapper {
        max-width: 351px;
        position: absolute;
        top: -193px;
        .ai-banner {
          display: none;
        }
        .ai-banner-mobile {
          display: block;
        }
      }
      .text {
        gap: 44px;
        > div {
          gap: 15px;
          flex-direction: column;
          img {
            max-width: 44px;
          }
        }
      }
    }
  }
`;

export const SetupAssistant = styled.div`
  background: #f7f7f7;
  .inner-wrapper {
    padding: 0 20px;
    display: flex;
    max-width: 1154px;
    align-items: center;
    margin: 0 auto;
    > div {
      flex: 1;
    }
    .left-section {
      .setup {
        border: 1px solid #f6f6f6;
        width: 253px;
        height: 101px;
        top: 1569px;
        border-radius: 30px;
        background-color: #fff;
        padding: 26px 33px 22px;
        box-shadow: 0px 0px 10px 1px #00000040;
        margin-bottom: 49px;
        margin-top: 78px;
        h4 {
          color: #8b2cf5;
          font-size: 21.61px;
          font-weight: 700;
          line-height: 26.15px;
          margin: 0;
          display: flex;
          align-items: center;
          gap: 5px;
        }
        h5 {
          font-size: 21.61px;
          font-weight: 700;
          line-height: 26.15px;
          color: #000000;
          margin: 0;
        }
      }
      .text {
        max-width: 281px;
        width: 100%;
        padding-left: 25px;
        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 28px;
          margin: 0;
          span {
            font-weight: 700;
          }
        }
      }
    }
    .right-section {
      max-width: 523px;
      background-image: url(${FullStackEngineerBG});
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      height: 621px;
      display: flex;
      align-items: center;
      .center-section {
        box-shadow: 0px 0px 10px 1px #00000040;
        border: 1px solid #bfbfbf;
        background: #ffffff;
        border-radius: 30px;
        padding: 37px 32px;
        max-width: 369px;
        margin: 0 auto;
        .round-section {
          border: 1px solid #c2c2c2;
          border-radius: 14px;
          position: relative;
          margin-bottom: 22px;
          h4 {
            font-size: 18.28px;
            font-weight: 700;
            line-height: 22.13px;
            margin: 0;
            padding: 18px 34px;
          }
          span {
            font-size: 7.33px;
            font-weight: 400;
            line-height: 8.87px;
            color: #868686;
            background-color: #fff;
            padding: 0 5px;
            position: absolute;
            top: -4px;
            left: 29px;
          }
        }
        .text {
          padding-left: 18px;
          padding-right: 27px;
          display: grid;
          position: relative;
          ::after {
            content: "";
            height: 100%;
            width: 100%;
            display: block;
            bottom: 0;
            left: 0;
            right: 0;
            position: absolute;
            background: linear-gradient(
              177deg,
              rgb(255 255 255 / 0%) 9%,
              rgb(255 255 255 / 20%) 56%
            );
          }
          p {
            font-size: 12px;
            font-weight: 700;
            line-height: 14.52px;
            margin-bottom: 11px;
          }
          ol {
            padding-left: 18px;
            margin: 0;
            font-size: 14px;
            font-weight: 400;
            line-height: 28px;
          }
          .bottom-icon {
            justify-self: flex-end;
            position: relative;
          }
        }
      }
    }
  }

  @media (max-width: 992px) {
    .inner-wrapper {
      display: block;
      padding: 0;
      .left-section {
        padding: 40px 20px 20px;
        .setup {
          margin-top: 0;
        }
      }
      .right-section {
        margin-top: 20px;
        background-repeat: repeat;
        max-width: 100%;
        background-size: contain;
        padding: 20px;
      }
    }
  }
`;

export const LiveInterview = styled.div`
  padding: 90px;
  .interview-wrapper {
    max-width: 1080px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    gap: 87px;
    align-items: center;
    color: #000000;
    justify-content: center;
    img {
      cursor:pointer;
    }
    .border {
      box-shadow: 0px 0px 10px 1px #00000040;
      border: 1px solid #f6f6f6;
      background: #ffffff;
      border-radius: 30px;
      overflow: hidden;
    }
    .row-1 {
      padding-bottom: 0;
      max-width: 311px;
    }
    .row-2 {
      display: flex;
      flex-direction: column;
      gap: 26px;
      max-width: 212px;
      img {
        max-width: 90px;
        height: auto;
      }
      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 21.78px;
        text-align: left;
        margin-top: 19px;
        margin-bottom: 0;
      }
      h2 {
        font-size: 29.42px;
        font-weight: 700;
        line-height: 35.6px;
        text-align: left;
        margin: 0;
      }
    }
  }
  .interview-banner {
    display: flex;

  }
  @media (max-width: 992px) {
    padding: 62px 20px;
    /* .interview-wrapper {
      display: grid;
      grid-template-columns: 1fr;
    }
    .interview-banner {
      img {
        max-width: 191px;
        margin: 0 auto;
        width: 100%;
      }
    } */
  }
  @media (max-width: 708px) {
    .interview-wrapper {
      gap: 20px;
      justify-content: space-between;
    }
  }
  @media (max-width: 613px) {
    .interview-wrapper {
      gap: 22px;
      flex-direction: column;
      .row-2 {
        flex-direction: row;
        max-width: 100%;
        justify-content: center;
        gap: 18px;
        img {
          max-width: 80px;
          cursor:pointer;
        }
        h2 {
          font-size: 18.62px;
          line-height: 22.53px;
          max-width: 115px;
          margin-top: 7px;
        }
        p {
          font-size: 11.39px;
          font-weight: 400;
          line-height: 13.79px;
          margin-top: 6px;
        }
      }
    }
  }
`;

export const Assessment = styled.div`
  background: #f7f7f7;
  .inner-wrapper {
    padding: 87px 20px 52px;
    align-items: center;
    max-width: 996px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .setup {
      border: 1px solid #f6f6f6;
      width: 253px;
      height: 101px;
      top: 1569px;
      border-radius: 30px;
      background-color: #fff;
      padding: 26px 33px 22px;
      box-shadow: 0px 0px 10px 1px #00000040;
      margin-bottom: 51px;
      text-align: center;
      h4 {
        color: #8b2cf5;
        font-size: 21.61px;
        font-weight: 700;
        line-height: 26.15px;
        margin: 0;
        display: flex;
        align-items: center;
        gap: 5px;
        span {
          color: #000;
        }
      }
      h5 {
        font-size: 21.61px;
        font-weight: 700;
        line-height: 26.15px;
        color: #000000;
        margin: 0;
      }
    }
    .left-section {
      max-width: 279px;
      width: 100%;
      > div {
        max-width: 253px;
        display: grid;
        margin-bottom: 56px;
        > img {
          max-width: 198px;
          margin: 0 auto;
        }
      }
      .text {
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
        span {
          font-weight: 700;
        }
      }
    }
    .right-section {
      display: flex;
      flex-direction: column;
      gap: 53px;
      align-items: center;
      padding-right: 60px;
      > img {
        max-width: 355px;
        width: 100%;
      }
      > button {
        padding: 12px 24px;
        font-size: 10px;
        font-weight: 700;
        line-height: 12.1px;
        color: #fff;
        background: #1071ad;
        border-radius: 10px;
        border: none;
        display: inline-flex;
        gap: 10px;
        align-items: center;
      }
    }
  }

  @media (max-width: 992px) {
    .inner-wrapper {
      display: block;
      .right-section {
        margin-top: 80px;
        padding-right: 5px;
      }
    }
  }
`;

export const AiPowered = styled.div`
  padding: 0px 0 50px;
  text-align: center;
  position: relative;
  .left-image {
    position: absolute;
    left: 0;
    width: 52px;
    bottom: 183px;
  }
  .right-image {
    position: absolute;
    right: 0;
    width: 52px;
    bottom: 183px;
  }
  > .wrapper {
    > h3 {
      font-weight: 700;
      padding: 0px 20px 40px;
      margin: 0;
    }
    > h4 {
      font-weight: 700;
      padding: 0px 20px 10px;
      margin: 0;
      span {
        color: #8b2cf5;
      }
    }
    .wrapper-box {
      display: flex;
      justify-content: center;
      padding: 0px 72px;
      gap: 30px;
      max-width: 1146px;
      width: 100%;
      margin: 0 auto;
      position: relative;
      .box {
        width: 100%;
        border: 1px solid #f6f6f6;
        border-radius: 30px;
        box-shadow: 0px 0px 10px 1px #00000040;
        padding: 50px 50px 28px;
        .box-image {
          width: 100%;
          height: 155px;
          object-fit: contain;
          margin-bottom: 30px;
        }
        > h4 {
          color: #000;
          font-size: 18px;
          font-weight: 700;
          line-height: 21.78px;
          margin-bottom: 24px;
          > span {
            color: #8b2cf5;
            font-weight: inherit;
            font-size: inherit;
          }
        }
        .media {
          display: flex;
        }
        .media__image {
          width: 20px;
          height: 20px;
          margin-right: 20px;
          margin-top: 3px;
        }
        .media__content {
        }
        .media__title {
          font-size: 18px;
          margin: 0;
        }
        .media__description {
          font-size: 10px;
          margin-bottom: 15px;
          text-align: left;
        }
      }
    }
  }

  /* Media Query */
  @media (max-width: 992px) {
    padding: 50px 0 20px;
    .left-image,
    .right-image {
      display: none;
    }
    > .wrapper {
      h3 {
        font-size: 20px;
      }
      .wrapper-box {
        display: block;
        justify-content: center;
        padding: 0px 20px;
        .box {
          width: 100%;
          border: 1px solid #f6f6f6;
          border-radius: 30px;
          box-shadow: 0px 0px 10px 1px #00000040;
          margin: 0px 0px 30px;
          max-width: 314px;
          margin: 0 auto 33px;
          padding: 50px 30px 30px;
          .box-image {
            width: 200px;
            height: auto;
            object-fit: contain;
            padding: 0px;
          }
          h4 {
            font-weight: 700;
            color: #000;
          }
          .media {
            display: flex;
            padding: 0;
          }
        }
      }
    }
  }
`;

export const Testimonials = styled.div`
  background: #f7f7f7;
  padding: 85px 0 135px;
  > .wrapper {
    max-width: 1049px;
    padding: 0 20px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 58px;
    align-items: center;
    .row-1 {
      .setup {
        border: 1px solid #f6f6f6;
        width: 253px;
        height: 101px;
        top: 1569px;
        border-radius: 30px;
        background-color: #fff;
        padding: 26px 33px 22px;
        box-shadow: 0px 0px 10px 1px #00000040;
        margin-bottom: 43px;
        text-align: center;
        display: inline-flex;
        align-items: center;
        h5 {
          font-size: 21.61px;
          font-weight: 700;
          line-height: 26.15px;
          color: #000000;
          margin: 0;
        }
      }
    }
    .box {
      color: #000000;
      h4 {
        font-size: 22.51px;
        font-weight: 700;
        line-height: 27.24px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        margin: 14px 0;
      }
      h5 {
        font-size: 18.51px;
        font-weight: 700;
        line-height: 22.4px;
        margin-bottom: 4px;
      }
      span {
        font-size: 12.51px;
        font-weight: 500;
        line-height: 15.14px;
        color: #000000;
      }
    }
  }
  .btn-wrapper {
    max-width: 1049px;
    padding: 0 20px;
    margin: 58px auto 0;
    justify-content: flex-end;
    display: flex;
    > button {
      padding: 12px 24px;
      font-size: 10px;
      font-weight: 700;
      line-height: 12.1px;
      color: #fff;
      background: #1071ad;
      border-radius: 10px;
      border: none;
      display: inline-flex;
      gap: 10px;
      align-items: center;
    }
  }

  @media (max-width: 992px) {
    .wrapper {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
`;
export const AboutUs = styled.div`
  background: #fff;
  padding: 40px 0 40px;
  .justify-text{
      text-align: start;
      font-weight: 500;

  }
  .justify-text p{
    line-height: 1.7;
    word-spacing: 2px;
 
  }

  @media (max-width: 992px) {
    .wrapper {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 450px;
  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const TabHeader = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: 992px) {
    gap: 0px;
  }
`;

export const Tab = styled.div`
  position: relative;
  padding: 10px 20px;
  font-size: 12px;
  z-index: 1;
  background-color: ${({ active }) => (active ? "#fff" : "")};
  font-weight: ${({ active }) => (active ? "600" : "400")};
  border: ${({ active }) => (active ? "1px solid #f0f0f0" : "")};
  box-shadow: ${({ active }) => (active ? "-3px -9px 10px 0px #00000040" : "")};
  border-bottom: 0px;
  color: ${({ active }) => (active ? "#000" : "#000")};
  border-radius: 10px 10px 0px 0px;
  cursor: pointer;
  margin-top: 20px;
  @media (max-width: 992px) {
    padding: 10px 10px;
  }
`;

export const TabContent = styled.div`
  padding: 20px 30px;
  background: #fff;
  border-radius: 5px;
  font-size: 12px;
  width: 100%;
  border: 1px solid #f6f6f6;
  box-shadow: 0px 0px 10px 1px #00000040;
  z-index: 0;
  margin-top: -3px; /* Move content up to overlap with tab shadow */
`;
export const ProgressContainer = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
`;

export const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ progress }) => `${progress}%`};
  height: 20px;
  background: linear-gradient(
    90deg,
    #ff0000 0%,
    #df8600 24.5%,
    #e1ca00 50.5%,
    #00ba13 76%,
    #002fd4 100%
  );
  border-radius: 20px;
`;

export const ProgressLabel = styled.span`
  left: ${({ progress }) => `${progress}%`};
  position: absolute;
  top: -40px;
  transform: translateX(-100%);
  background: #fff;
  padding: 5px 25px;
  border-radius: 10px;
  font-weight: 800;
  box-shadow: 0px 0px 10px 1px #00000040;
`;

export const HowItWork = styled.div`
  .outer-wrapper {
    background-color: #262970;
  }
  .mobile-left-section {
    display: none;
  }
  .wrapper {
    max-width: 1144px;
    padding: 122px 20px 78px;
    margin: 0 auto;
    display: flex;
    /* min-height: 619px; */
    > div {
      flex: 1;
    }
    .left-section {
      max-width: 302px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      h2 {
        font-size: 42.7px;
        font-weight: 700;
        line-height: 51.68px;
        text-align: left;
        color: #fff;
      }
      img {
        width: 28px;
        height: 28px;
      }
    }
    .slider-wrapper {
      max-width: 800px;
      .slick-next:before,
      .slick-prev:before {
        display: none;
      }
      .slick-disabled {
        display: none !important;
      }
      .slick-next,
      .slick-prev {
        width: 56px;
        height: 28px;
        padding: 6px 5px;
        bottom: 68px;
        top: unset;
        z-index: 1;
      }
      .slick-next {
        right: 80px;
      }
      .slick-prev {
        right: 154px;
        left: unset;
      }
      .slider-inner-wrapper {
        > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .img-section {
            max-width: 465px;
            width: 100%;
            img {
              width: 100%;
            }
            .bottom-img {
              max-width: 245px;
              margin: 40px auto 0;
            }
          }
          .text-section {
            display: flex;
            flex-direction: column;
            gap: 13px;
            color: #ffffff;
            padding-bottom: 78px;
            align-items: flex-start;
            .mobile-view-tab {
              display: none;
            }
            h3 {
              font-size: 20px;
              font-weight: 400;
              line-height: 24.2px;
              margin: 0;
            }
            h2 {
              font-size: 20px;
              font-weight: 700;
              line-height: 24.2px;
              margin: 0;
            }
            p {
              font-size: 17px;
              font-weight: 400;
              line-height: 20.57px;
              max-width: 204px;
              margin: 0;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1032px) {
    .wrapper .slider-wrapper {
      max-width: 716px;
      margin: 0 auto;
      width: 100%;
      padding-right: 5px;
    }
  }

  @media (max-width: 992px) {
    /* display: none; */
    .wrapper {
      padding: 52px 20px 80px;

      .left-section {
        display: none;
      }
      .slider-wrapper {
        .slider-inner-wrapper > div .img-section {
          max-width: 325px;
        }
      }
    }

    .mobile-left-section {
      display: flex;
      justify-content: center;
      h2 {
        font-size: 49.35px;
        font-weight: 700;
        line-height: 59.73px;
        margin-bottom: 50px;
      }
    }
  }
  @media (max-width: 625px) {
    .wrapper .slider-wrapper {
      max-width: 325px;
      .slick-prev,
      .slick-next {
        bottom: -13px;
      }
      .slick-next {
        right: 0;
      }
      .slick-prev {
        right: 77px;
      }
      .slider-inner-wrapper > div {
        flex-direction: column;
        gap: 38px;

        .text-section {
          position: relative;
          height: 234px;
          .mobile-view-tab {
            display: inline-flex;
            font-size: 12px;
            background: #fff;
            padding: 7px 15px;
            color: #000;
            font-weight: 700;
            border-radius: 15px;
            margin: 0;
            position: absolute;
            bottom: 0;
            span {
              color: #8b2cf5;
              margin-left: 3px;
            }
          }
          p {
            max-width: 278px;
          }
        }
        .img-section .bottom-img {
          display: none;
        }
      }
    }
  }
`;
